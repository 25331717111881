import React from "react"
import { ASSET_URL, CHOSEN_THEME } from '../constants'
import Layout from "../components/layout"
import SEO from "../components/seo"
import IframeResizer from 'iframe-resizer-react'
// import MarketHero from '../components/MarketHero'
// refactor to gatsby theme ui:
import '../assets/styles/App.css'
//*/ 

const embeddedUrl = `${ASSET_URL}?embed=${CHOSEN_THEME}`

const Market = () => (
  <Layout>
    <SEO title="Home" />
     <div className="App">
        
        {/* <MarketHero />  */}

        <IframeResizer
          log
          src={embeddedUrl}
          style={{ width: '100%', minWidth: '100%', frameBorder: '0'}}
          id="opensea-iframe"
          title="Embedded OpenSea Marketplace"
          allowFullScreen
        />                  

      </div>
  </Layout>
)

export default Market